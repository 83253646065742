import { useEffect, useState} from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit';

function Header (){
    const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          setIsScrollValueMoreThanHeaderHeight(window.scrollY >= 10);
      }
      window.addEventListener('scroll', handleScroll)
        return () => {
        return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return(
        <div id='header' className={isScrollValueMoreThanHeaderHeight ? "header sticky" : "header"}>
            <img
                src={require("./assets/img/logo.png")}
                alt="logo"
                className="logo"
            />

            {/* <button href="#" className="header-cta"><ConnectButton/></button> */}
            <ConnectButton/>
        </div>
    )
}

export default Header