import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
// import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from "wagmi/providers/public";
import Header from "./Header";
import Landing from "./Landing";

function App() {
  const { chains, provider } = configureChains(
    [chain.mainnet],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "WIN",
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <div className="wrapper">
          <Header />
          <Landing />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
