import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

export function createMerkleTree(whilelistAddresses) {
console.log("here before leaf");
  const leafNodes = whilelistAddresses?.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  return merkleTree;
}

export function returnMerkleProof(address, merkleTree) {
  const claimingAddress = keccak256(address);
  const hexProof = merkleTree.getHexProof(claimingAddress);

  return hexProof;
}