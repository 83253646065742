import { useState } from "react";

export function InProgress({ popupProp, setshowLoading }) {
  const [popupVisibility, setPopupVisibility] = useState(true);

  const handlePopupVisibility = () => {
    setshowLoading("");
    setPopupVisibility(!popupVisibility);
  };
  return (
    <div
      className={
        popupVisibility ? "popup-container" : "popup-container invisible"
      }
    >
      <div className="popup-box">
        {/* inprogress message */}
        <div className="popup-icon">
          <img
            src={require("../assets/img/in-progress.gif")}
            alt="in progress"
          />
        </div>
        {popupProp ? (
          <div>
            <p className="message">
              Please wait while token minting is in progress..
            </p>
            <p className="trans-hash">
              Transaction ID:{" "}
              <a
                href={`https://etherscan.io/tx/${popupProp}`}
                target="_blank"
                className="bold-text"
              >
                {popupProp}
              </a>
            </p>
          </div>
        ) : (
          <p className="message">Please Sign Transaction ...</p>
        )}

        <button className="popup-btn" onClick={handlePopupVisibility}>
          ok
        </button>
      </div>
    </div>
  );
}

export function IsSuccess({ popupProp, setshowLoading }) {
  const [popupVisibility, setPopupVisibility] = useState(true);
  const handlePopupVisibility = () => {
    setshowLoading("");
    setPopupVisibility(!popupVisibility);
  };
  return (
    <div
      className={
        popupVisibility ? "popup-container" : "popup-container invisible"
      }
    >
      <div className="popup-box">
        <div className="popup-icon">
          <img src={require("../assets/img/success.gif")} alt="success" />
        </div>
        <p className="message">Successful token minting.</p>
        <p className="trans-hash">
          Transaction ID:{" "}
          <a
            href={`https://etherscan.io/tx/${popupProp.response}`}
            target="_blank"
            className="bold-text"
          >
            {popupProp.response}
          </a>
        </p>
        <p className="message">
          NFT ID: <span className="bold-text">{popupProp.log}</span>
        </p>
        <button className="popup-btn" onClick={handlePopupVisibility}>
          ok
        </button>
      </div>
    </div>
  );
}

export function IsError({ popupProp, setshowLoading }) {
  const [popupVisibility, setPopupVisibility] = useState(true);
  const handlePopupVisibility = () => {
    setshowLoading("");
    setPopupVisibility(!popupVisibility);
  };
  return (
    <div
      className={
        popupVisibility ? "popup-container" : "popup-container invisible"
      }
    >
      <div className="popup-box">
        <div className="popup-icon">
          <img src={require("../assets/img/faliure.gif")} alt="faliure" />
        </div>
        {popupProp.reason ? (
          <p className="message">
            {" "}
            <span className="bold-text">Failed! : </span>
            {popupProp.reason}
          </p>
        ) : popupProp.message ? (
          <div>
            <p className="message">
              {" "}
              <span className="bold-text">Failed! : </span>
              {popupProp.message}
            </p>
          </div>
        ) : (
          <div>
            <p className="message">
              {" "}
              <span className="bold-text">Failed! : </span>
              {popupProp}
            </p>
          </div>
        )}
        <button className="popup-btn" onClick={handlePopupVisibility}>
          ok
        </button>
      </div>
    </div>
  );
}
