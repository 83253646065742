import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useProvider, useSigner, useAccount } from "wagmi";
import { ethers } from "ethers";
import { contractAbi, contractAddress } from "./Contract";
import { InProgress, IsSuccess, IsError } from "./Popups/InProgress";
import winList from "./win-list-addresses";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import { createMerkleTree, returnMerkleProof } from "./scripts/merkle";

window.Buffer = window.Buffer || require("buffer").Buffer;
function Landing() {
  const [maxRange, setmaxRange] = useState(20);
  const [currentSupply, setcurrentSupply] = useState(0);
  const [totalSupply, settotalSupply] = useState(7777);
  const [Count, setCount] = useState(1);
  const [maxPerWallet, setmaxPerWallet] = useState(0);
  const [ownedPerWallet, setownedPerWallet] = useState(null);
  const [showLoading, setshowLoading] = useState(false);
  const [popupProp, setpopupProp] = useState(null);
  const [isWinListActive, setisWinListActive] = useState(false);
  const [isPublicSale, setisPublicSale] = useState(false);
  const [price, setprice] = useState("0.055");

  // const provider = useProvider();
  // console.log("provider: ", provider);
  const provider = useProvider({
    chainId: 5,
  });
  const { data } = useSigner();
  const account = useAccount();

  const contract = new ethers.Contract(
    contractAddress,
    contractAbi.abi,
    provider
  );

  const contractWithSigner = contract.connect(data);
  const _currentSupply = contractWithSigner._currentSupply();
  const _totalSupply = contractWithSigner._totalSupply();
  const _maxrange = contractWithSigner._maxPerWallet();
  const _ownedPerWallet = contractWithSigner.minted(account.address);
  const _maxPerWallet = contractWithSigner._maxPerWallet();
  const _isPublicSaleActive = contractWithSigner._isPublicSaleActive();
  const _isWinListSaleActive = contractWithSigner._isWinListSaleActive();

  _currentSupply.then((result) => setcurrentSupply(result.toString()));
  _totalSupply.then((result) => settotalSupply(result.toString()));
  _maxrange.then((result) => setmaxRange(result.toString()));
  _ownedPerWallet.then((result) => setownedPerWallet(result.toString()));
  _maxPerWallet.then((result) => setmaxPerWallet(result.toString()));
  _isPublicSaleActive.then((result) => setisPublicSale(result));
  _isWinListSaleActive.then((result) => setisWinListActive(result));
  // _price.then((result) => setprice(formatEther(result.toString())));

  // const merkleRoot = createMerkleTree(Whitelist?.list);
  if (account) {
    var proof = isWhiteList(account.address);
    var eligible = contractWithSigner.checkWinListEligibility(
      account.address,
      proof
    );
    eligible.then((result) => {
      if (result) {
        const _price = contractWithSigner.price("winList");
        _price.then((result) => setprice(formatEther(result.toString())));
      } else {
        const _price = contractWithSigner.price("public");
        _price.then((result) => setprice(formatEther(result.toString())));
      }
    });
  }
  // useEffect(() => {}, []);

  useEffect(() => {
    if (account) {
      var proof = isWhiteList(account.address);
      var eligible = contractWithSigner.checkWinListEligibility(
        account.address,
        proof
      );
      eligible.then((result) => {
        if (result) {
          const _price = contractWithSigner.price("winList");
          _price.then((result) => setprice(formatEther(result.toString())));
        } else {
          const _price = contractWithSigner.price("public");
          _price.then((result) => setprice(formatEther(result.toString())));
        }
      });
    }
  }, [account.address]);

  function isWhiteList(account) {
    const merkleTree = createMerkleTree(winList.list);
    console.log("0x" + merkleTree.getRoot().toString("hex"));
    console.log(account);
    const proof = returnMerkleProof(account, merkleTree);
    return proof;
  }

  async function mint() {
    if (!isWinListActive && !isPublicSale) {
      // alert("Sale is not active.");
      setshowLoading("IsError");
      setpopupProp({ message: "Error", reason: "Sale is not active." });
    } else {
      setpopupProp(null);
      setshowLoading("InProgress");

      try {
        console.log("debugg status", isPublicSale, isWinListActive);
        var proof = isWhiteList(account.address);
        var eligible = await contractWithSigner.checkWinListEligibility(
          account.address,
          proof
        );
        if (isWinListActive && eligible) {
          let value = price * Count;
          const response = await contractWithSigner.mintWinList(Count, proof, {
            value: ethers.utils.parseEther(value.toString()),
          });
          setpopupProp(response.hash);
          setshowLoading("InProgress");
          const log = await response.wait();
          setshowLoading("IsSuccess");
          setpopupProp({
            response: response.hash,
            log: parseInt(log.logs[0].topics[3]),
          });
        } else if (isPublicSale) {
          // console.log("debugg Count:: ", Count);
          // console.log("debugg Value:: ", price);
          let value = price * Count;
          value = parseEther(value.toString());
          // console.log("debugg Total Value:: ", value.toString());

          const response = await contractWithSigner.mintPublic(Count, {
            value: value,
          });
          setpopupProp(response.hash);
          setshowLoading("InProgress");
          const log = await response.wait();
          setshowLoading("IsSuccess");
          setpopupProp({
            response: response.hash,
            log: parseInt(log.logs[0].topics[3]),
          });
        } else {
          setshowLoading("IsError");
          setpopupProp({ message: "Error", reason: "Public Sale not active." });
        }
      } catch (error) {
        console.log(error);
        setshowLoading("IsError");
        setpopupProp({ message: error?.message, reason: error?.reason });
      }
    }
  }

  useEffect(() => {
    // isWhiteList();
    $(".wrap").addClass("loaded");

    $(".range").bind("change mousemove", function () {
      // var val = $(this).val() * 10;
      // var buf = (100 - val) * 4 + parseInt(val);
      var val = ($(this).val() / maxRange) * 100;
      var buf = maxRange;

      console.log("buf is: ", buf);
      console.log("val is: ", val);
      $(this).css(
        "background",
        "linear-gradient(to right, #b54da4 0%, #b54da4 " +
          val +
          "%, #fbfbfb " +
          val +
          "%, #fbfbfb " +
          buf +
          "%, #fbfbfb " +
          buf +
          "%, #fbfbfb 100%)"
      );
    });
  }, []);
  return (
    <section className="landing-section">
      <h1 className="sub-title">STEM Intense NFT</h1>
      <div className="left-col">
        <h2 className="main-title">MINTING NOW</h2>
        <div className="mint-status">
          <h2 className="mint-count">
            {currentSupply} / {totalSupply} MINTED
          </h2>

          <div className="range-slider">
            <span>{Count}</span>
            <div className="wrap">
              <input
                type="range"
                className="range"
                min={1}
                max={maxRange}
                step="1"
                value={Count}
                onChange={(event) => {
                  if (
                    parseInt(event.target.value) + parseInt(ownedPerWallet) >
                    parseInt(maxPerWallet)
                  ) {
                    setshowLoading("IsError");
                    setpopupProp("Max Wallet Limit Reached !");
                  } else {
                    setCount(event.target.value);
                  }
                }}
              />
            </div>
            <span>{maxRange}</span>
          </div>

          <p className="cost-calculation">
            {Count} x {price} ETH
          </p>
          <p className="total-cost">
            TOTAL : {(Count * price).toFixed(3)} ETH + GAS
          </p>
        </div>
        <button className="mint-cta" onClick={mint}>
          MINT
        </button>
      </div>
      {showLoading === "InProgress" ? (
        <InProgress popupProp={popupProp} setshowLoading={setshowLoading} />
      ) : showLoading === "IsSuccess" ? (
        <IsSuccess popupProp={popupProp} setshowLoading={setshowLoading} />
      ) : showLoading === "IsError" ? (
        <IsError popupProp={popupProp} setshowLoading={setshowLoading} />
      ) : (
        ""
      )}
    </section>
  );
}

export default Landing;
