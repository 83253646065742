const winList = {
  win: "win1",
  list: [
    "0xDf2AC80661DAB9010f9B9cc88e43f3d2E1035fF4",
    "0x9F7Df40b967bf83626057b373432C3319BbdE750",
    "0x730793a73379c331CeBc37aF922b55B11Ca9d779",
    // production values
    "0x7Aa5eDF69120924a8Ed81F664f99628968b40E99",
    "0x74E180aB92D98340D39DEdD0BDBd37313B96c84c",
    "0xE9aa33d146719881Fc0390c7315006161c1ad065",
    "0xA7fdf68dd141B1e1174906DC27080D0fd2C99Cb9",
    "0x19c703553243F22DdeB0A54e1dac23F1C2AB7D49",
    "0xb05c74a36c8c1e84940a595ba76873e2424a002c",
    "0x3314b669ea4b1168162803f326d205e5d539c89e",
    "0x457273F05adc671eF9214E24ADF710B5481D94BD",
    "0xd4ed5ec2c9ee28e00ea50c20698dd8b97aa78c3b",
    "0xFc806953fA005612B83C29A674D7B9B5129a5a23",
    "0xf6eDFCC054F5Ff98223D98660eC2065d120581Ab",
    "0x42F713c8092a51a9C4db857754a1Ac6635CA41E3",
    "0x64Cfc8e35e2844C8Ed8045d4bd6EfC66D182f690",
    "0x768058a4b0054dc1cb025889b2efd9c2051d2bf6",
    "0xc2F82cCCd3995593DE074758DC49d355FF99888a",
    "0xCB2B948a4F3Bb7d95D3352c6c58285391A9d2C24",
    "0x89dbbE140E60AA066a2629299507b9FF703F9715",
    "0x8E381E69D14C205A34Cc63664A80C0B5EAD96999",
    "0x64cf6839A04A1e52E8b70731B267753564f85965",
    "0x0Fc1e682f966e272599bCC53cF4F8622303aeF0e",
    "0xa950Be28065aC97Ef6EdB4Cbf5dAd09CAb212A19",
    "0x87BAa1f9c0D486D3dD923524695AeE2ea66348dc",
    "0xbcdaf410eE02a08549788fe99e103Ad189B5a716",
    "0x52bafba2d753bd2ef104a9db7f28959279b1e4e9",
    "0x4F613e0caBBCF5ad1AaE3433d1C359FF2a03E0B5",
    "0x86508532F72F1F78F95369eA7605f6EAE727e659",
    "0x57F5d5081e7aa8CCD34B2CDf76e63DCb9A8Cc9f5",
    "0x6d136a41FFD40E52dd6897F3C599753FB21A9107",
    "0xaD5652076A69F23999AD0a85A5FB6089ab021Db1",
    "0x59800551f9dEfA8c6Db36CFB137f0B9c8c6Ba9E7",
    "0xd4BCaa911825ca0FC050511DaD1B985A9851f4FB",
    "0xd78a7ef483107dcd84e663e1955c4cde9cad2467",
    "0x1be54E8396127480347E2EcB32eE6f39cc234aa4",
    "0xd78a7ef483107dcd84e663e1955c4cde9cad2467",
    "0x3Ca15fE6Ca7c69BEB647b47041Fa2b54Fb02cc33",
    "0x99F1A4dcfa89d16B49bc344Bfb069a067DfF1Bb4",
    "0xf4b55AF23C01B397bF88Ce8A19aee70b3519b456",
    "0x7a7972D2731b30D34A861683eCB2B5EeF55446da",
    "0xDE0c12Ada427f29871e00587c2Fff5b68ABb2531",
    "0xAc5E98dE667779E9FE2FE8C13A86ba60AB06b071",
    "0xeAb5E1756cbD66B037d338A0D86006520E3554e2",
    "0x14994DF8EFFc19606648319970B46Bd915fCC8Bf",
  ],
};

export default winList;
